// Transpiler for older browsers
import 'core-js/stable';
// optional but required for transforming generator fns.
import 'regenerator-runtime/runtime'; 


import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import vex from 'vex-js'
import i18n from './i18n'
import auth from './util/auth'
import acl from '@/../../../templates/site/UI/app/acl-rules'
import constants from '@/../../../templates/site/UI/common/constants.js'
require('../node_modules/vex-js/dist/css/vex.css')
require('../node_modules/vex-js/dist/css/vex-theme-os.css')

Vue.config.productionTip = false

vex.registerPlugin(require('vex-dialog'))
vex.defaultOptions.className = 'vex-theme-os'
// add dialog lib to instance
Vue.prototype.$vex = vex

const http = axios.create({
  baseURL: constants.API_URL,
  headers: {
    'Access-Control-Allow-Origin': constants.ORIGIN,
    'Content-type': 'application/json'
  }
})

Vue.prototype.$http = http
Vuex.Store.prototype.$http = http
// store.state.$i18n = i18n

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

// handle authenticated routes and user record on store/app
router.beforeEach((to, from, next) => {
  if (!store.getters.const.merged) {
    auth
      .getConfig({ $http: http })
      .then(res => {
        if (res.status !== 200 || !res.data.success) throw new Error(res.response)
        store.commit('MERGE_CONFIG', Object.assign(res.data.config, {merged: true}))
      })
      .catch(err => {
        console.warn('app error in request for config', err)
      })
    // get system translations as well
  }
  if (to.meta && to.meta.title) document.title = typeof to.meta.title === 'function' ? i18n.messages[i18n.locale][to.meta.title(constants)] : to.meta.title
  if (to.meta && to.meta.bodyClass) document.body.className = to.meta.bodyClass
  if (to.requiresAuth) {
    auth
      .checkAuth({ $http: http })
      .then(res => {
        if (res.success) {
          store.commit('AUTHENTICATED_USER', res.user)
        } else {
          store.commit('LOGOUT')
        }
        return res
      }) // then get permissions for user and add to store
      .then(res => {
        if (!res.success) return
        const _routepermission = auth.routePermitted(to, acl)
        if (_routepermission) {
          if (typeof _routepermission === 'boolean') {
            next()
          } else {
            next(_routepermission)
          }
        } else {
          if (to.root) {
            window.location.assign(window.location.origin)
          } else {
            next(new Error(`not allowed to go to ${to.path}.`))
          }
        }
      })
      .catch(err => {
        console.info(' ::: route aut eror', err)
        store.commit('REQ_ERROR', err)
        next('/login')
      })
  } else {
    // if (!store.state.App.user) store.commit('LOGIN', auth.getUser())
    next()
  }
})
