// import _ from 'lodash/fp'
import * as types from '../../mutation-types'

//
// CART & ORDER
//
const actions = {
  clearCartMemory ({ commit }, type) {
    // empty cart on profile
    commit(types.CLEAR_CART, type)
    commit(types.CLEAR_PRODUCTS, type)
    // dispatch('emptyCart', type)
  }
}

export default actions
