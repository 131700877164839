import _ from 'lodash/fp'
import {
  LOGOUT,
  GOT_PRODUCTS,
  GOT_PRODUCT,
  GOT_PRODUCT_DETAIL,
  SET_CONTEXT,
  GOT_COLLECTION_LIST,
  GOT_COLLECTION_PRODUCTS,
  SHOW_PRODUCT_FAMILY,
  GOT_PREORDER_PRODUCTS,
  GOT_CUSTOMORDER_PRODUCTS,
  CLEAR_PRODUCTS
} from '../../mutation-types'

const mutations = {
  [LOGOUT] (state) {
    state.products = []
    state.productFamilies = {}
    state.productFamily = {}
    state.familyVariations = []
    state.atomicFamilies = []
    state.listFamilies = []
    state.atomicProduct = {}
    state.customimages = []
    state.product = null
    state.allCollectionProducts = []
    state.atomicFamilyCache = []
    state.familyVariationCache = []
  },
  [GOT_PRODUCTS] (state, products) {
    state.products = products.slice()
    // state.productFamilies = productUtil.familyGroups(products);
    // state.displayProducts = productUtil.familyHeads(products);
  },
  [GOT_PRODUCT] (state, product) {
    state.product = _.assign({}, product)
  },
  [GOT_PRODUCT_DETAIL] (state, data) {
    console.info(' ::: mutation data', data)
    state.atomicProduct = _.assign({}, data.data.product)
    state.atomicFamilies = _.concat(
      state.atomicFamilies.slice(),
      data.data.family
    )
    state.customimages = _.concat(
      state.customimages.slice(),
      data.data.customimages
    )
  },
  [SET_CONTEXT] (state, context) {
    state.filteredSet = context.products || []
  },
  [GOT_COLLECTION_LIST] (state, data) {
    if (typeof data === 'string') return
    state.listFamilies = data.data.products
  },
  [GOT_COLLECTION_PRODUCTS] (state, data) {
    if (typeof data === 'string') return
    const products = data.data.products
    if (data.data.customimages) state.customimages = data.data.customimages
    const _familygroups = _.groupBy('familyKey', products)
    const _pa = []
    for (const k in _familygroups) {
      // const _resolvekey = _familygroups[k][0].variationField.key
      const _v = _.groupBy('variationField.key', _familygroups[k])
      for (const i in _v) {
        if (_v[i].length > 1) {
          const _itm = _.assign({}, _v[i][0])
          _itm.items = []
          for (const j in _v[i]) {
            _itm.items.push(_.assign({}, _v[i][j]))
          }
          _pa.push(_itm)
        } else {
          _pa.push(_.assign({}, _v[i][0]))
        }
      }
    }
    _pa.forEach(i => {
      const _r = []
      for (const itm in i.relatedItems) {
        _r.push(_.find(p => p.SKU === itm, _pa))
      }
    })

    state.atomicFamilies = null
    state.atomicFamilies = _pa.slice()
    state.atomicFamilyCache = _pa.slice()
    state.products = products
    state.productFamilies = _.assign({}, _familygroups)
  },
  [SHOW_PRODUCT_FAMILY] (state, paramkeys) {
    let [familyKey, variationKey] = paramkeys.split('/')
    if (typeof variationKey !== 'string') variationKey = ''
    state.atomicProduct = _.find(
      p => p.key === familyKey + variationKey,
      state.atomicFamilies
    )
    // state.productFamily = _.reduce(
    //   (acc, p) => {
        const members = _.filter(
          prod => prod.familyKey === familyKey,
          state.atomicFamilies
        )
        if (members.length > 1) {
          state.productFamily = _.groupBy(m => {
            return m.variationField.key || ''
          }, members)
        } else state.productFamily = []
    //   },
    //   {},
    //   state.atomicFamilies
    // )
  },
  [GOT_PREORDER_PRODUCTS] (state, products) {
    state.preorderProducts = products
  },
  [GOT_CUSTOMORDER_PRODUCTS] (state, products) {
    state.customorderProducts = products
  },
  [CLEAR_PRODUCTS] (state) {
    state.products = []
    state.productFamilies = {}
    state.productFamily = {}
    state.familyVariations = []
    state.atomicFamilies = []
    state.listFamilies = []
    state.atomicProduct = {}
    state.customimages = []
    state.product = null
    state.allCollectionProducts = []
    state.atomicFamilyCache = []
    state.familyVariationCache = []
  }
}

export default mutations
