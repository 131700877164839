import _ from 'lodash/fp'
import {
  LOGOUT,
  GOT_ORDER,
  GOT_ORDERS,
  SHIPPING_ADDRESS,
  BILLING_ADDRESS,
  START_ORDER,
  UPDATE_CREDIT,
  ADD_TXN,
  CLEAR_TXN,
  UPDATE_SHIPPING_RATE,
  CLEAR_SHIPPING,
  // ORDER_IS_RECEIPT,
  NEW_ORDER,
  UPDATE_ORDER,
  CLEAR_ORDER,
  UPDATE_METHOD,
  SET_CC_PROFILE
} from '@/store/mutation-types'
import accounting from 'accounting'

const mutations = {
  [LOGOUT] (state) {
    state.order = null
  },
  [START_ORDER] (state, order) {
    let _group
    let _order
    if (order.groupId) {
      _order = _.assign({}, order)
      if (order.parent) {
        // the root container for the multiloc order group
        _group = {
          groupId: _order.groupId,
          purchaser: _order.purchaser,
          collection: _order.collection,
          price: _order.parentPrice,
          billing: _order.billing,
          paymentMethod: _order.paymentMethod,
          shippingService: _order.shippingService,
          orders: {}
        }
        _group.orders[order.shipping.location] = _.omit(
          ['parent', 'parentPrice'],
          _order
        )
      } else {
        _group = _.assign({}, _.omit('parentPrice', state.order))
        _group.orders[order.shipping.location] = _.omit(
          ['parent', 'parentPrice'],
          _order
        )
        // _group.price.subtotal = _.sum(Object.values(_group.orders).map(o => o.price.subtotal))
        _group.price.shipping = _.sum(Object.values(_group.orders).map(o => o.price.shipping))
        _group.price.total = _.sum(Object.values(_group.orders).map(o => o.price.total))
        // _group.price.subtotal += _order.price.subtotal
      }
      state.order = _.assign({}, _group)
    } else {
      state.order = _.assign({}, order)
    }
  },
  [NEW_ORDER] (state, order) {
    state.order = _.assign({}, order)
  },
  [GOT_ORDER] (state, order) {
    if (order.err) {
      console.info('GOT_ORDER', order)
    }
    state.order = _.assign({}, order)
  },
  [GOT_ORDERS] (state, orders) {
    state.orders = orders
  },
  [UPDATE_CREDIT] (state, credit) {
    const _order = _.assign({}, state.order)
    if (!credit) {
      delete _order.price.credit
      _order.credit = false
    } else {
      _order.credit = credit
      _order.price.credit = Number(accounting.toFixed(credit, 2))
    }
    state.order = _.assign({}, _order)
  },
  [UPDATE_METHOD] (state, method) {
    const _order = _.assign({}, state.order)
    _order.paymentMethod = method
    state.order = _.assign({}, _order)
  },
  [SET_CC_PROFILE] (state, profile) {
    const _order = _.assign({}, state.order)
    _order.paymentProfile = {DataKey: profile.DataKey, ResCardType: profile.ResCardType}
      // typeof profile === 'string'
      //   ? { DataKey: profile }
      //   : { DataKey: profile.DataKey } // _.merge(_order.paymentProfile, profile);
    state.order = _.assign({}, _order)
  },
  [UPDATE_SHIPPING_RATE] (state, data) {
    const _order = _.assign({}, state.order)
    const _data = _.assign({}, data)

    if (_order.shippingService && _order.shippingService.rawdata) {
      _order.shippingService.rawdata = {}
    }
    _order.shippingService = _.assign(_order.shippingService, _data.selected)
    _order.price.shipping = _data.selected.price || _data.selected.rate
    if (_order.groupId) {
      // multi-loc for contortionists
      const _locs = _.reduce(
        (acc, i) => {
          acc[i] = {}
          const _rate =
            _data.selected.carrier === 'UPS'
              ? _data.rates.rates[i][_data.selected.carrier].RatedShipment.find(
                  r => _data.selected.serviceCode === r.Service.Code
                )
              : _data.rates.rates[i][_data.selected.carrier].find(
                  r => _data.selected.serviceCode === r.service.code
                )
          acc[i][_data.selected.carrier] =
            _data.selected.carrier === 'UPS'
              ? {
                  price: _rate.TotalCharges.AdjustedRate,
                  rate: _.assign({}, _rate)
                }
              : { price: _rate.price.adjustedBase, rate: _.assign({}, _rate) }
          return acc
        },
        {},
        _.keys(_data.rates.rates)
      )
      for (const l in _order.orders) {
        let _ord = _.assign({}, _order.orders[l])
        const _price = _.assign(_ord.price, {
          shipping: _locs[l][_data.selected.carrier].price
        })
        _ord = _.assign(_ord, {
          price: _price,
          shippingService: _locs[l][_data.selected.carrier].rate
        })
        _order.orders[l] = _.assign({}, _ord)
      }
    }
    state.order = _.assign({}, _order)
  },
  [CLEAR_SHIPPING] (state) {
    const _order = _.assign({}, state.order)
    if (_order.shippingService) delete _order.shippingService
    _order.price.shipping = 0
    state.order = _.assign({}, _order)
  },
  [UPDATE_ORDER] (state, data) {
    // console.info(' ::: update', data.data)
    let _order = _.assign({}, state.order)
    if (data.multilocation) {
      if (data.taxes) {
        _order.price.taxes = _.assign(_order.price.taxes, data.data.summary)
        _order.price.total = Number(
          accounting.toFixed(
            _order.price.subtotal +
              _order.price.shipping +
              _.sum(_.values(_order.price.taxes)),
            2
          )
        )
        for (const l in _order.orders) {
          const _ord = _.assign({}, _order.orders[l])
          const _rec = data.data.orders.find(_o => l === _o.taxaddr.location)
          _ord.price.taxes = _.assign(_ord.price.taxes, _rec.tax)
          _ord.price = _.assign(_ord.price, {
            taxes: _ord.price.taxes,
            total: Number(
              accounting.toFixed(
                _ord.price.subtotal +
                  _ord.price.shipping +
                  _.sum(_.values(_ord.price.taxes)),
                2
              )
            )
          })
          // _ord.price.shipping = _order.shippingService.carrier === 'UPS'
          //   ? (Number)(_ord.shippingService.TotalCharges.AdjustedRate)
          //   : _ord.shippingService.price.adjustedBase
          // _.each(t => {
          //   _ord.price.taxes[t.toLowerCase()] = _rec.tax[t]
          // }, _.keys(_rec.tax))
          _order.orders[l] = _.assign({}, _ord)
        }
      } else if (data.shippingTBD) {
        const _o = _order.orders.find(o => o.shipping.first === data.location)
        _o.shippingService = _.omit(['multilocation', 'location'], data)
        _o.price.shippingTBD = true
        _o.price.total = Number(
          accounting.toFixed(
            _o.price.subtotal + data.rate + data.taxes
              ? _.sum(_.values(data.taxes))
              : 0,
            2
          )
        )
      }
    } else {
      if (data.taxes) {
        _order.price.taxes = data.taxes
        _order.price.total = Number(
          accounting.toFixed(
            _order.price.subtotal +
              _order.price.shipping +
              _.sum(_.values(data.taxes)),
            2
          )
        )
      } else if (data.shippingTBD) {
        _order.price.shippingTBD = true
        _order.shippingService = data
        _order.price.total = Number(
          accounting.toFixed(
            _order.price.subtotal + data.rate + _.sum(_.values(data.taxes)),
            2
          )
        )
        //   _order.price.total = (Number)(accounting.toFixed(_order.price.subtotal + _order.price.shipping + _.sum(_.values(data.taxes)), 2));
      } else if (data.complete) {
        _order = data.order
      } else {
        // console.info(' :::: test fake txn state/in', _order, data)
        if (_order.paymentProfile && !data.paymentProfile) data.paymentProfile = _order.paymentProfile
        _order = data
      }
    }
    state.order = _order
  },
  [SHIPPING_ADDRESS] (state, address) {
    const _order = _.assign({}, state.order)
    _order.shipping = _.assign({}, address)
    state.order = _order
  },
  [BILLING_ADDRESS] (state, address) {
    const _order = _.assign({}, state.order)
    _order.billing = _.assign({}, address)
    state.order = _order
  },
  [ADD_TXN] (state, txn) {
    const _order = _.assign({}, state.order)
    _order.txn = txn
    state.order = _.assign({}, _order)
  },
  [CLEAR_TXN] (state) {
    const _order = _.assign({}, state.order)
    _order.txn = null
    state.order = _.assign({}, _order)
  },
  [CLEAR_ORDER] (state) {
    state.order = null // new Order().getData()
  }
}

export default mutations
