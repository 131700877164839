<template src="@/../../../templates/brand/components/templates/MainHeader.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import accounting from 'accounting'
import SupportForm from './Support.vue'

export default {
  components: { SupportForm },
  created () {
    this.getCollections()
    this.getPrograms()
  },
  props: {
    menuItems: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
    // this will fail if we don't have the user yet...
    // if (_.keys(this.user.profile.cart.items).length) {
    let _carts = []
    if (!_.isEmpty(this.user.profile.cart.items)) _carts.push('cart')
    _carts = _carts.concat(_.keys(this.user.profile.shoppingcarts)).filter(c => c && c !== "undefined")
    if (_carts.length) {
      _.each(c => {
        this.getCartProducts(c)
      }, _carts)
    }
  },
  data () {
    return {
      showTicket: false
    }
  },
  computed: {
    ...mapState({
      collections: ({ Collection }) =>
        _.sortBy(item => item.order, Collection.collections),
      programs: ({ Program }) => Program.programs,
      cartdata: ({ Appui }) => Appui.cartcount,
      customdata: ({ Appui }) => Appui.customcount,
      customcartdata: ({ Appui }) => Appui.customcarts,
      user: ({ App }) => App.user
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    cartIsEmpty: function () {
      return !this.cartLinks.length
      // if (!this.user || !this.user.profile.cart) return true;
      // return !_.keys(this.user.profile.cart.items).length;
    },
    customorderIsEmpty: function () {
      if (!this.user || !this.user.profile || !this.user.profile.customorders) return true
      return !_.keys(this.user.profile.customorders.items).length
    },
    customcartsEmpty () {
      const cartvals = _.values(this.user.profile.shoppingcarts)
      if (cartvals.length) {
        return !cartvals.some(i => _.sum(_.values(i.items)) > 0)
      }
      return true
    },
    preorderIsEmpty: function () {
      if (!this.user || !this.user.profile || !this.user.profile.preorders) return true
      return !_.keys(this.user.profile.preorders.items).length
    },
    cartLinks: function () {
      const _carts = []
      if (!this.user || !this.user.profile) return _carts
      if (_.keys(this.user.profile.cart.items).length) {
        let _cart = this.collections.find(c => c.type === 'product')
        if (_cart) {
          _carts.push({
            title: _cart.content[this.$i18n.locale].name,
            route: 'cartView',
            path: '/cart/' + _cart.url,
            params: { cartId: _cart.url },
            query: { cartid: 'cart' },
            type: 'cart',
            data: this.cartdata
          })
        }
      }
      if (_.keys(this.user.profile.customorders.items).length) {
        let _cust = this.collections.find(c => c.type === 'custom')
        if (_cust) {
          _carts.push({
            title: _cust.content[this.$i18n.locale].name,
            route: 'cartView',
            path: '/cart/' + _cust.url,
            params: { cartId: _cust.url },
            query: { cartid: 'custom' },
            type: 'customorders',
            data: this.customdata
          })
        }
      }
      const _customcarts = _.get('shoppingcarts', this.user.profile)
      if (!_.isEmpty(_customcarts)) {
        _.each(c => {
          if (_.keys(_customcarts[c].items).length) {
            _carts.push({
              title: (_customcarts[c].label && _customcarts[c].label[this.$i18n.locale].name) || 'bad cart',
              route: 'cartView',
              path: '/cart/' + c,
              params: { cartId: c },
              query: { cartid: c },
              type: '',
              data: this.customcartdata[c]
            })
          }
        }, _.keys(_customcarts))
      }
      return _carts
    }
  },
  methods: {
    ...mapActions([
      'getCollections',
      'getPrograms',
      'getPageMenu',
      'activate',
      'getCartProducts',
      'getCustomorderProducts',
      'profileUpdate',
      'logout'
    ]),
    logOut () {
      this.logout()
      this.$router.push('/login')
    },
    hideSupport () {
      setTimeout(() => {
        this.showTicket = false
      }, 2000)
    },
    currencyLocale: function (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    },
    setLocale: function (locale) {
      const _profile = _.assign({}, this.user.profile)
      _profile.lang = locale
      this.$root.$i18n.locale = locale
      document.documentElement.lang = locale
      this.profileUpdate({ user: this.user, profile: _profile })
      // this.activate(locale);
    }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/MainHeader.css"></style>
