const regions = [
  { 
    name: 'Canada',
    default: true,
    countryCode: 'CA',
    postalcode: true,
    postalcodeName: 'Postal Code',
    postalcodeLength: 7,
    postalcodePlaceholder: 'X0X 0X0',
    province: true,
    provinceLabel: 'Province',
    provinceList: [
      { name: 'Alberta', provinceCode: 'AB' },
      { name: 'British Columbia', provinceCode: 'BC' },
      { name: 'Manitoba', provinceCode: 'MB' },
      { name: 'New Brunswick', provinceCode: 'NB' },
      { name: 'Newfoundland and Labrador', provinceCode: 'NL' },
      { name: 'Nova Scotia', provinceCode: 'NS' },
      { name: 'Northwest Territories', provinceCode: 'NT' },
      { name: 'Nunavut', provinceCode: 'NU' },
      { name: 'Ontario', provinceCode: 'ON' },
      { name: 'Prince Edward Island', provinceCode: 'PE' },
      { name: 'Quebec', provinceCode: 'QC' },
      { name: 'Saskatchewan', provinceCode: 'SK' },
      { name: 'Yukon', provinceCode: 'YT' }
    ],
  },
  { 
    name: 'United States',
    countryCode: 'USA',
    postalcode: true,
    postalcodeName: 'ZIP Code',
    postalcodeLength: 5,
    postalcodePlaceholder: '12345',
    province: true,
    provinceLabel: 'State',
    provinceList: [
      { name: 'Alabama', provinceCode: 'AL' },
      { name: 'Alaska', provinceCode: 'AK' },
      { name: 'Arizona', provinceCode: 'AZ' },
      { name: 'Arkansas', provinceCode: 'AR' },
      { name: 'California', provinceCode: 'CA' },
      { name: 'Colorado', provinceCode: 'CO' },
      { name: 'Connecticut', provinceCode: 'CT' },
      { name: 'Delaware', provinceCode: 'DE' },
      { name: 'Florida', provinceCode: 'FL' },
      { name: 'Georgia', provinceCode: 'GA' },
      { name: 'Hawaii', provinceCode: 'HI' },
      { name: 'Idaho', provinceCode: 'ID' },
      { name: 'Illinois', provinceCode: 'IL' },
      { name: 'Indiana', provinceCode: 'IN' },
      { name: 'Iowa', provinceCode: 'IA' },
      { name: 'Kansas', provinceCode: 'KS' },
      { name: 'Kentucky', provinceCode: 'KY' },
      { name: 'Louisiana', provinceCode: 'LA' },
      { name: 'Maine', provinceCode: 'ME' },
      { name: 'Maryland', provinceCode: 'MD' },
      { name: 'Massachusetts', provinceCode: 'MA' },
      { name: 'Michigan', provinceCode: 'MI' },
      { name: 'Minnesota', provinceCode: 'MN' },
      { name: 'Mississippi', provinceCode: 'MS' },
      { name: 'Missouri', provinceCode: 'MO' },
      { name: 'Montana', provinceCode: 'MT' },
      { name: 'Nebraska', provinceCode: 'NE' },
      { name: 'Nevada', provinceCode: 'NV' },
      { name: 'New Hampshire', provinceCode: 'NH' },
      { name: 'New Jersey', provinceCode: 'NJ' },
      { name: 'New Mexico', provinceCode: 'NM' },
      { name: 'New York', provinceCode: 'NY' },
      { name: 'North Carolina', provinceCode: 'NC' },
      { name: 'North Dakota', provinceCode: 'ND' },
      { name: 'Ohio', provinceCode: 'OH' },
      { name: 'Oklahoma', provinceCode: 'OK' },
      { name: 'Oregon', provinceCode: 'OR' },
      { name: 'Pennsylvania', provinceCode: 'PA' },
      { name: 'Rhode Island', provinceCode: 'RI' },
      { name: 'South Carolina', provinceCode: 'SC' },
      { name: 'South Dakota', provinceCode: 'SD' },
      { name: 'Tennessee', provinceCode: 'TN' },
      { name: 'Texas', provinceCode: 'TX' },
      { name: 'Utah', provinceCode: 'UT' },
      { name: 'Vermont', provinceCode: 'VT' },
      { name: 'Virginia', provinceCode: 'VA' },
      { name: 'Washington', provinceCode: 'WA' },
      { name: 'West Virginia', provinceCode: 'WV' },
      { name: 'Wisconsin', provinceCode: 'WI' },
      { name: 'Wyoming', provinceCode: 'WY' }
    ]
  }
]

export default regions