import Home from '@/views/Home.vue'
import Page from '@/views/Page.vue'
const Login = () =>
  import(/* webpackChunkName: "login" */ '@/views/user/Login.vue')
const Signup = () =>
  import(/* webpackChunkName: "signup" */ '@/views/user/Signup.vue')
const Activate = () =>
  import(/* webpackChunkName: "login" */ '@/views/user/Activate.vue')
const Profile = () =>
  import(/* webpackChunkName: "profile" */ '@/views/user/Profile.vue')
const MyAccount = () =>
  import(/* webpackChunkName: "myaccount" */ '@/views/user/MyAccount.vue')
const Password = () =>
  import(/* webpackChunkName: "password" */ '@/views/user/Password.vue')
const Collection = () =>
  import(/* webpackChunkName: "collection" */ '@/views/shop/Collection.vue')
const Program = () =>
  import(/* webpackChunkName: "program" */ '@/views/shop/Program.vue')
const Programs = () =>
  import(/* webpackChunkName: "programs" */ '@/views/shop/Programs.vue')
const Product = () =>
  import(/* webpackChunkName: "proddetail" */ '@/views/shop/ProductDetailWrapper.vue')
const Cart = () =>
  import(/* webpackChunkName: "cart" */ '@/views/shop/Cart.vue')
const Checkout = () =>
  import(
    /* webpackChunkName: "checkout" */ '@/views/shop/checkout/Checkout.vue'
  )
const CustInfo = () =>
  import(
    /* webpackChunkName: "checkout" */ '@/views/shop/checkout/CustomerInfo.vue'
  )
const Shipping = () =>
  import(
    /* webpackChunkName: "checkout" */ '@/views/shop/checkout/Shipping.vue'
  )
const Reserve = () =>
  import(/* webpackChunkName: "checkout" */ '@/views/shop/checkout/Reserve.vue')
const Receipt = () =>
  import(/* webpackChunkName: "checkout" */ '@/views/shop/checkout/Receipt.vue')
const ReservationReceipt = () =>
  import(
    /* webpackChunkName: "checkout" */ '@/views/shop/checkout/ReservationReceipt.vue'
  )
const Order = () =>
  import(/* webpackChunkName: "order" */ '@/views/user/Order.vue')
const OrderDetails = () =>
  import(/* webpackChunkName: "orderdetail" */ '@/components/user/OrderDetails.vue')
const Invoice = () =>
  import(/* webpackChunkName: "orderdetail" */ '@/components/user/Invoice.vue')
const Reservation = () =>
  import(/* webpackChunkName: "reservation" */ '@/views/user/Reservation.vue')
const ReservationDetails = () =>
  import(
    /* webpackChunkName: "reservationsdetail" */ '@/components/user/ReservationDetails.vue'
  )
const GiftCredit = () =>
  import(/* webpackChunkName: "ecerts" */ '@/components/user/GiftCredit.vue')
const PaymentProfiles = () =>
  import(
    /* webpackChunkName: "payments" */ '@/components/user/PaymentProfiles.vue'
  )
const ProgramOrders = () =>
  import(/* webpackChunkName: "programorders" */ '@/components/user/ProgramOrders.vue')

  import constants from '@/../../../templates/site/UI/common/constants'
  const siteConfig = constants.siteConfig

const routes = [
  {
    name: 'Login',
    path: '/login',
    component: Login,
    meta: {
      title: `${siteConfig.siteName} | Login`,
      public: true,
      bodyClass: 'login'
    },
    requiresAuth: false
  },
  {
    name: 'Signup',
    path: '/signup',
    component: Signup,
    meta: {
      title: `${siteConfig.siteName} | Create an Account`,
      public: true,
      bodyClass: 'signup'
    },
    requiresAuth: false
  },
  {
    path: '/users/activate',
    component: Activate,
    meta: {
      title: `${siteConfig.siteName} | Activate Your Account`,
      public: true,
      bodyClass: 'activate'
    },
    requiresAuth: false
  },
  {
    path: '/users/reset',
    component: Activate,
    meta: {
      title: `${siteConfig.siteName} | Activate Your Account`,
      public: true,
      bodyClass: 'reset'
    },
    requiresAuth: false
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    permission: '/',
    meta: {
      title: `${siteConfig.siteName}`,
      bodyClass: 'home'
    },
    requiresAuth: true
  },
  {
    path: '/myaccount',
    component: MyAccount,
    name: 'myaccount',
    meta: {
      title: `${siteConfig.siteName} | My Account`,
      bodyClass: 'myaccount'
    },
    requiresAuth: true,
    children: [
      {
        path: 'profile',
        component: Profile,
        meta: {
          title: `${siteConfig.siteName} | My Account - Profile`,
          bodyClass: 'myaccount profile'
        }
      },
      {
        path: 'password',
        component: Password,
        meta: {
          title: `${siteConfig.siteName} | My Account - Change Password`,
          bodyClass: 'myaccount password'
        }
      },
      {
        path: 'orders',
        component: Order,
        name: 'myorders',
        meta: {
          title: `${siteConfig.siteName} | My Account - Orders`,
          bodyClass: 'myaccount orders'
        }
      },
      {
        path: 'orders/custom',
        component: Order,
        name: 'mycustomorders',
        meta: {
          title: `${siteConfig.siteName} | My Account - Orders`,
          bodyClass: 'myaccount orders'
        }
      },
      {
        path: 'reservations',
        component: Reservation,
        name: 'myreservations',
        meta: {
          title: `${siteConfig.siteName} | My Account - Orders`,
          bodyClass: 'myaccount orders'
        }
      },
      {
        path: 'orders/:orderid',
        component: OrderDetails,
        name: 'myorderdetails',
        meta: {
          title: `${siteConfig.siteName} | My Account - Order Details`,
          bodyClass: 'myaccount details'
        }
      },
      {
        path: 'orders/:orderid/invoice',
        component: Invoice,
        name: 'myinvoice',
        meta: {
          title: `${siteConfig.siteName} | My Account - Invoice`,
          bodyClass: 'myaccount invoice'
        }
      },
      {
        path: 'orders/custom/:orderid',
        component: OrderDetails,
        name: 'mycustomorderdetails',
        meta: {
          title: `${siteConfig.siteName} | My Account - Order Details`,
          bodyClass: 'myaccount details'
        }
      },
      {
        path: 'reservations/:reservationid',
        component: ReservationDetails,
        name: 'myreservationdetails',
        meta: {
          title: `${siteConfig.siteName} | My Account - Order Details`,
          bodyClass: 'myaccount details'
        }
      },
      {
        path: 'paymentprofiles',
        component: PaymentProfiles,
        meta: {
          title: `${siteConfig.siteName} | My Account - Payment Methods`,
          bodyClass: 'myaccount payment'
        }
      },
      {
        path: 'ecertificates',
        component: GiftCredit,
        // requiresAuth: true,
        permission: '/myaccount/ecertificates',
        meta: {
          title: `${siteConfig.siteName} | My Account - eCertificates`,
          bodyClass: 'myaccount ecerts'
        }
      },
      {
        path: 'preorders',
        component: ProgramOrders,
        meta: {
          title: `${siteConfig.siteName} | My Account - Program Orders`,
          bodyClass: 'myaccount orders'
        }
      }
    ]
  },
  {
    path: '/shop/:collection',
    component: Collection,
    meta: {
      title: `${siteConfig.siteName} | Shop`,
      meta: [{ attribute: 'description', content: `Shop for ${siteConfig.brand}` }],
      bodyClass: 'collection'
    },
    requiresAuth: true
  },
  {
    name: 'collectionCategoryProducts',
    path: '/shop/:collection/category/:cat1*',
    component: Collection,
    meta: {
      title: `${siteConfig.siteName} | Shop`,
      bodyClass: 'category'
    },
    requiresAuth: true
  },
  {
    name: 'collectionProduct',
    path: '/shop/:collection/product/:fkey/:vkey?',
    component: Product,
    meta: {
      title: `${siteConfig.siteName} | Shop`,
      bodyClass: 'product'
    },
    requiresAuth: true
  },
  {
    name: 'programs',
    path: '/programs',
    component: Programs,
    meta: {
      title: `${siteConfig.siteName} | Shop`,
      meta: [{ attribute: 'description', content: `Shop for ${siteConfig.brand} Gear` }],
      bodyClass: 'programs'
    },
    requiresAuth: true
  },
  {
    name: 'program',
    path: '/programs/:program',
    component: Program,
    meta: {
      title: `${siteConfig.siteName} | Shop`,
      meta: [{ attribute: 'description', content: `Shop for ${siteConfig.brand} Gear` }],
      bodyClass: 'program'
    },
    requiresAuth: true
  },
  {
    name: 'programCategoryProducts',
    path: '/programs/:program/category/:cat1*',
    component: Program,
    meta: {
      title: `${siteConfig.siteName} | Shop`,
      bodyClass: 'program'
    },
    requiresAuth: true
  },
  {
    name: 'programProduct',
    path: '/programs/:program/product/:fkey/:vkey?',
    component: Product,
    meta: {
      title: `${siteConfig.siteName} | Shop`,
      bodyClass: 'product'
    },
    requiresAuth: true
  },
  {
    path: '/programs/:program/reserve',
    component: Checkout,
    meta: {
      title: `${siteConfig.siteName} | Checkout`,
      bodyClass: 'programs'
    },
    requiresAuth: true,
    children: [
      {
        name: 'reserve',
        path: 'customerinfo',
        component: CustInfo
      },
      {
        path: 'shipping',
        component: Shipping
      },
      {
        name: 'reservePayment',
        path: 'payment',
        component: Reserve
      }
    ]
  },
  {
    name: 'reservationReceipt',
    path: '/programs/:program/reserve/receipt',
    component: ReservationReceipt,
    meta: {
      bodyClass: 'programs'
    },
    requiresAuth: true
  },
  {
    path: '/customize/checkout',
    component: Checkout,
    meta: {
      title: `${siteConfig.siteName} | Checkout`,
      bodyClass: 'checkout'
    },
    requiresAuth: true,
    children: [
      {
        name: 'customCheckout',
        path: 'customerinfo',
        component: CustInfo
      },
      {
        path: 'shipping',
        component: Shipping
      },
      {
        name: 'customPayment',
        path: 'payment',
        component: Reserve
      }
    ]
  },
  {
    name: 'customReceipt',
    path: '/customize/checkout/receipt',
    component: ReservationReceipt,
    requiresAuth: true,
    meta: {
      bodyClass: 'checkout'
    }
  },
  {
    name: 'cartView',
    path: '/cart/:cartId',
    component: Cart,
    meta: {
      title: `${siteConfig.siteName} | Shopping Cart`,
      bodyClass: 'cart'
    },
    requiresAuth: true
  },
  {
    path: '/checkout',
    component: Checkout,
    meta: {
      title: `${siteConfig.siteName} | Checkout`,
      bodyClass: 'checkout'
    },
    requiresAuth: true,
    children: [
      {
        name: 'checkout',
        path: 'customerinfo',
        component: CustInfo
      },
      {
        path: 'shipping',
        component: Shipping
      },
      {
        name: 'checkoutPayment',
        path: 'payment',
        component: Reserve,
        meta: { component: 'reserve' }
      }
    ]
  },
  {
    name: 'receipt',
    path: '/checkout/receipt',
    component: Receipt,
    requiresAuth: true,
    meta: {
      vault: true,
      bodyClass: 'checkout'
    }
  },
  {
    path: '/vault',
    component: Receipt,
    requiresAuth: true
  },
  {
    path: '/content/:page',
    name: 'page',
    component: Page,
    requiresAuth: true,
    meta: {
      bodyClass: 'home'
    }
    // public: true
  },
  {
    // Utility routes?
    path: '/ajax/success',
    component: {
      template: '<h2>Success! All done.</h2>'
    },
    requiresAuth: false,
    public: true
  },
  {
    path: '/ajax/failure',
    component: {
      template: '<h2>Success! All done.</h2><p>Error: {{err}}',
      data: () => {
        return { err: '' }
      },
      created () {
        this.err = this.$route.query
      }
    },
    requiresAuth: false,
    public: true
  },
  { path: '/checkout', redirect: '/checkout/customerinfo' },
  { path: '/customize/checkout', redirect: '/customize/checkout/customerinfo' },
  {
    path: '/programs/:program/reserve',
    redirect: '/programs/:program/reserve/customerinfo'
  },
  { path: '*', redirect: '/' }
]

export default routes
